import React, { useState } from "react";
import { Text, Heading, Img } from "./..";
import { useLocation } from "react-router-dom";

export default function Header() {
  const pathName = useLocation();
  const [toggleSideBar, setSidebarToggle] = useState(false);
  function openSidebar() {
    setSidebarToggle((prevToggle) => !prevToggle);
  }
  return (
    <header className="flex justify-center items-center w-full px-[100px] md:px-[20px] py-[20px] bg-gray-50">
      <div
        className="overlay"
        onClick={() => {
          openSidebar();
        }}
        style={toggleSideBar ? { right: 0 } : { right: "100%" }}
      ></div>
      <div className="flex flex-row justify-between items-center w-full">
        <a
          href="/"
          className="flex flex-row justify-start items-center w-[20%] gap-[18px]"
        >
          <Img
            src="images/img_image_1.png"
            alt="imageone_one"
            className="w-[35%] object-cover"
          />
          <Heading
            as="h5"
            className="w-[100%] sm:text-[12px] sm:leading-[18px] text-[24px] leading-[30px]"
          >
            ज्योतिरादित्य मा. सिंधिया
          </Heading>
        </a>
        <div
          className="flex sm:hidden flex-row justify-between items-center w-auto gap-[70px] nav-list"
          style={toggleSideBar ? { right: 0 } : { right: "-200px" }}
        >
          <a href="/">
            <Text
              as="p"
              size="xl"
              className={
                pathName?.pathname === "/"
                  ? "!text-yellow-900"
                  : "hover:text-yellow-900 transition-all duration-300"
              }
            >
              होम
            </Text>
          </a>
          <a href="/parichay">
            <Text
              size="xl"
              as="p"
              className={
                pathName?.pathname === "/parichay"
                  ? "!text-yellow-900"
                  : "hover:text-yellow-900 transition-all duration-300"
              }
            >
              परिचय
            </Text>
          </a>
          <a href="/gallery">
            <Text
              size="xl"
              as="p"
              className={
                pathName?.pathname === "/gallery"
                  ? "!text-yellow-900"
                  : "hover:text-yellow-900 transition-all duration-300"
              }
            >
              गैलरी
            </Text>
          </a>
          {/* <a href="/vade-irade">
            <Text
              as="p"
              size="xl"
              className={
                pathName?.pathname === "/vade-irade"
                  ? "!text-yellow-900"
                  : "hover:text-yellow-900 transition-all duration-300"
              }
            >
              वादे और इरादे
            </Text>
          </a> */}
          <a href="/press">
            <Text
              as="p"
              size="xl"
              className={
                pathName?.pathname === "/press"
                  ? "!text-yellow-900"
                  : "hover:text-yellow-900 transition-all duration-300"
              }
            >
              प्रेस एवं मीडिया
            </Text>
          </a>
          <a href="/contact">
            <Text
              as="p"
              size="xl"
              className={
                pathName?.pathname === "/contact"
                  ? "!text-yellow-900"
                  : "hover:text-yellow-900 transition-all duration-300"
              }
            >
              सम्पर्क करें
            </Text>
          </a>
        </div>
        <button
          className="navbar-toggler hidden items-center justify-center p-2 rounded-md sm:flex"
          aria-label="Toggle navigation"
          onClick={() => openSidebar()}
        >
          <svg
            className="navbar-toggler-icon w-6 h-6 text-gray-700"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
    </header>
  );
}
