import React from "react";

const sizes = {
  "5xl": "text-[32px] font-normal leading-[48px]",
  xs: "text-xs font-normal",
  lg: "text-lg font-normal leading-[27px]",
  s: "text-sm font-medium leading-[21px]",
  "2xl": "text-[22px] font-medium",
  "3xl": "text-2xl font-medium leading-9",
  "4xl": "text-[26px] font-medium leading-[39px]",
  xl: "text-xl font-normal leading-[30px]",
  md: "text-base font-medium leading-6",
};

const Text = ({ children, className = "", as, size = "lg", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-black-900_01 font-poppins ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
