import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";

export default function DesktopNinePage() {
  const [year, setYear] = useState("2002-2004");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start w-full">
        <div className="flex flex-col items-center justify-start w-full gap-[9px]">
          <Header />
          <div className="hero-section-2">
            <Img
              src="images/img/parichay-1.png"
              alt="imageremovebg"
              className="h-[478px] sm:h-[300px] object-cover"
            />
            <div className="flex flex-col items-start justify-start w-[100%] mb-[17px] mr-7 sm:mr-0">
              <Heading size="md" as="h1">
                परिचय
              </Heading>
              <div className="h-[4px] w-[9%] mt-3.5 ml-px bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[3px] mt-[-4px] ml-[75px] bg-green-A700 rounded-[1px]" />
              <div className="h-[4px] w-[3px] mt-[-4px] ml-[79px] bg-green-A700 rounded-[1px]" />
              <Text
                size="xl"
                as="p"
                className="mt-[31px] ml-px !font-rhodiumlibre !leading-10 sm:!leading-[20px] sm:text-[16px]"
              >
                जनसेवा को अपना ध्येय और राजनीति को उसका माध्यम मात्र मानने वाले
                ज्योतिरादित्य सिंधिया वर्तमान में भारत सरकार में केंद्रीय नागरिक
                उड्डयन एवं इस्पात मंत्री के रूप में कार्यरत हैं। वे मध्यप्रदेश
                से चार बार लोकसभा सांसद रहे हैं (2002-04, 2004-09, 2009-14,
                2014-19) एवं वर्तमान में वे मध्यप्रदेश से राज्यसभा सदस्य के रूप
                में राज्य का प्रतिनिधित्व कर रहे हैं। अपनी स्कूली शिक्षा द दून
                स्कूल, देहरादून से पूरी करने के पश्चात सिंधिया ने हार्वर्ड
                विश्वविद्यालय, अमेरिका से अर्थशास्त्र में डिग्री प्राप्त की और
                उच्च शिक्षा के लिए उन्होंने स्टैनफोर्ड विश्ववि‌द्यालय से
                एम.बी.ए. की डिग्री हासिल की। इसके साथ ही वे संयुक्त राष्ट्र,
                मॉर्गन स्टेनली और मेरिल लिंच जैसी विख्यात कंपनियों में एक
                इन्वेस्टमेंट बैंकर के रूप में भी कार्य कर चुके हैं।
              </Text>
            </div>
          </div>
          <div className="jan-slider">
            <div className="jan-section">
              <div className="flex flex-col items-start justify-start pt-[97px] pl-[100px] sm:pl-[20px] w-[70%] sm:w-[100%]">
                {year === "2002-2004" && (
                  <>
                    <Text
                      size="5xl"
                      as="p"
                      className="w-[40%] sm:w-[100%] !text-white-A700 !leading-[50px]"
                    >
                      2002-2004
                      <br />
                      (जनसेवा का प्रारंभ)
                    </Text>
                    <div className="flex flex-row justify-start w-[26%] mt-[13px] gap-1">
                      <div className="h-[4px] w-[89%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                    </div>
                    <Text
                      as="p"
                      className="w-[98%] mt-[39px] !text-white-A700 !font-rhodiumlibre !leading-10"
                    >
                      सक्रिय राजनीति के माध्यम से जनसेवा की अपनी यात्रा श्री
                      ज्योतिरादित्य सिंधिया ने वर्ष 2002 से प्रारंभ की जब वे
                      पहली बार गुना लोकसभा क्षेत्र से सांसद बने। अपने इस प्रथम
                      कार्यकाल में उन्होंने गुना के समग्र विकास का लक्ष्य
                      शिरोधार्य करते हुए सांसद नहीं अपितु स्वयं को एक सेवक मानकर
                      विभिन्न कार्य कराए।
                      <br />
                      इस अवधि में शिक्षा, स्वास्थ्य और अधोसंरचना निर्माण जैसे
                      प्रमुख क्षेत्रों को प्राथमिकता से लेते हुए श्री सिंधिया ने
                      कई ऐसे विकास कार्य प्रारंभ कराए जो आगे चलकर 'समृद्ध गुना -
                      विकसित गुना' के संकल्प को साकार करने की दिशा में मील का
                      पत्थर साबित हुए।
                    </Text>
                  </>
                )}
                {year === "2004-2009" && (
                  <>
                    <Text
                      size="5xl"
                      as="p"
                      className="w-[70%] sm:w-[100%] !text-white-A700 !leading-[50px]"
                    >
                      2004-2009
                      <br />
                      (गुना के विकास का नया अध्याय)
                    </Text>
                    <div className="flex flex-row justify-start w-[26%] mt-[13px] gap-1">
                      <div className="h-[4px] w-[89%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                    </div>
                    <Text
                      as="p"
                      className="w-[98%] mt-[39px] !text-white-A700 !font-rhodiumlibre !leading-10"
                    >
                      एक कार्यकाल सफलतापूर्वक पूर्ण करने के पश्चात जनसेवा और
                      गुना के सतत विकास की उनकी इच्छा बलवती होती चली गयी जिसका
                      प्रतिसाद देते हुए जनता ने उन्हें वर्ष 2004 में पुनः अपने
                      सांसद के रूप में चुना। इस दूसरे कार्यकाल के दौरान उन्होंने
                      अपने निर्वाचन क्षेत्र में बुनियादी ढांचे को मजबूत करने,
                      स्वास्थ्य सुविधाओं के विकास और क्षेत्रभर में जन-जन तक पानी
                      की उपलब्धता सुनिश्चित करने के लिए बड़े पैमाने पर काम किया।
                      <br />
                      अप्रैल 2008 - मई 2009 संचार एवं सूचना प्रौद्यौगिकी राज्य
                      मंत्री श्री सिंधिया ने 500 डाकघरों में प्रोजेक्ट एरो की
                      शुरुआत की जिससे भारतीय पोस्ट एक उत्तरदायी व अधिक लाभकारी
                      संगठन में परिवर्तित हुआ। इसके साथ ही इस सकारात्मक
                      परिणामदायी परियोजना को 'सार्वजनिक क्षेत्र की सर्वश्रेष्ठ
                      परियोजना' के लिए प्रधानमंत्री पुरस्कार भी प्राप्त हुआ।
                    </Text>
                  </>
                )}
                {year === "2009-2014" && (
                  <>
                    <Text
                      size="5xl"
                      as="p"
                      className="w-[70%] sm:w-[100%] !text-white-A700 !leading-[50px]"
                    >
                      2009-2014
                      <br />
                      (नवाचारों के पर्याय)
                    </Text>
                    <div className="flex flex-row justify-start w-[26%] mt-[13px] gap-1">
                      <div className="h-[4px] w-[89%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                    </div>
                    <Text
                      as="p"
                      className="w-[98%] mt-[39px] !text-white-A700 !font-rhodiumlibre !leading-10"
                    >
                      मई 2009 - नवंबर 2012 - संसद सदस्य, 15वीं लोकसभा
                      <br />
                      वाणिज्य एवं उद्योग राज्य मंत्री: ज्योतिरादित्य सिंधिया ने
                      लेनदेन लागत को कम करने और व्यवसायों के लिए सेवा उन्मुख
                      वातावरण बनाने पर ध्यान केंद्रित किया। उनके कार्यकाल के
                      दौरान ई-ब्रिज और ई-ट्रेड पोर्टल का कार्य प्रारंभ हुआ। साथ
                      ही मंत्रालय के तहत भारतीय चाय को दोबारा ब्रांड बनाने की
                      परियोजना भी टास्क फोर्स के तहत सम्मिलित हुई।
                    </Text>
                  </>
                )}
                {year === "2014-2019" && (
                  <>
                    <Text
                      size="5xl"
                      as="p"
                      className="w-[70%] sm:w-[100%] !text-white-A700 !leading-[50px]"
                    >
                      2014-2019
                      <br />
                      (प्रगति की नई गाथा)
                    </Text>
                    <div className="flex flex-row justify-start w-[26%] mt-[13px] gap-1">
                      <div className="h-[4px] w-[89%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                      <div className="h-[4px] w-[4%] bg-deep_orange-A100 rounded-sm" />
                    </div>
                    <Text
                      as="p"
                      className="w-[98%] mt-[39px] !text-white-A700 !font-rhodiumlibre !leading-10"
                    >
                      नवंबर 12 मई 2014 विद्युत राज्य मंत्री (स्वतंत्र प्रभार):
                      <br />
                      श्री सिंधिया ने सफलतापूर्वक स्मार्ट सिम रिट सिस्टम स्थापित
                      किया, जिससे देश में peak कमी और ऊर्जा की कमी को क्रमश:
                      4.2% और 4.5% तक कम किया गया, साथ ही कुछ क्षेत्रों में 0%
                      बिजली की कमी भी हासिल की गई। <br />
                      सिंधिया ने एक निजी विधेयक प्रस्तुत किया जिसमें कुपोषण
                      उन्मूलन में मदद के लिए एक तंत्र का प्रस्ताव किया गया। अपने
                      कार्यकाल के दौरान उन्होंने किसानों की आत्महत्या, महिलाओं
                      की सुरक्षा, अल्पसंख्यक समूहों के कल्याण और आर्थिक विकास
                      जैसे मुद्दों पर भी लगातार संसद का ध्यान आकर्षित किया।
                    </Text>
                  </>
                )}
                <div>
                  <div className="overflow-x-auto pb-0 sm:pb-[20px] w-full overflow-y-hidden sm:mt-[40px] mt-[100px]">
                    <div className="flex sm:flex-wrap items-center w-full gap-[13px]">
                      <div className="flex w-full flex-col items-center relative justify-start gap-[10px]">
                        <Text
                          onClick={() => setYear("2002-2004")}
                          size="xl"
                          as="p"
                          className="!text-white-A700 text-center w-full !font-outfit"
                        >
                          2002-2004
                        </Text>
                        {year === "2002-2004" && (
                          <div className="text-line"></div>
                        )}
                        <div className="flex flex-row sm:hidden justify-start gap-[13px] bottom-0 relative">
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                        </div>
                      </div>
                      <div className="h-[41px] w-[10px] sm:hidden rotate-[180deg] bg-white-A700 relative bottom-[-7px]" />
                      <div className="flex w-full flex-col items-center relative justify-start gap-[10px]">
                        <Text
                          onClick={() => setYear("2004-2009")}
                          size="xl"
                          as="p"
                          className="!text-white-A700 text-center w-full !font-outfit"
                        >
                          2004-2009
                        </Text>
                        {year === "2004-2009" && (
                          <div className="text-line"></div>
                        )}
                        <div className="flex flex-row sm:hidden justify-start gap-[13px] bottom-0 relative">
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                        </div>
                      </div>
                      <div className="h-[41px] w-[10px] sm:hidden rotate-[180deg] bg-white-A700 relative bottom-[-7px]" />
                      <div className="flex w-full flex-col items-center relative justify-start gap-[10px]">
                        <Text
                          size="xl"
                          onClick={() => setYear("2009-2014")}
                          as="p"
                          className="!text-white-A700 text-center w-full !font-outfit"
                        >
                          2009-2014
                        </Text>
                        {year === "2009-2014" && (
                          <div className="text-line"></div>
                        )}
                        <div className="flex flex-row sm:hidden justify-start gap-[13px] bottom-0 relative">
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                        </div>
                      </div>
                      <div className="h-[41px] w-[10px] sm:hidden rotate-[180deg] bg-white-A700 relative bottom-[-7px]" />
                      <div className="flex w-full flex-col items-center relative justify-start gap-[10px]">
                        <Text
                          size="xl"
                          as="p"
                          onClick={() => setYear("2014-2019")}
                          className="!text-white-A700 text-center w-full !font-outfit"
                        >
                          2014-2019
                        </Text>
                        {year === "2014-2019" && (
                          <div className="text-line"></div>
                        )}
                        <div className="flex flex-row sm:hidden justify-start gap-[13px] bottom-0 relative">
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                          <div className="h-[13px] w-[2px] rotate-[180deg] bg-white-A700" />
                        </div>
                      </div>
                      <div className="h-[41px] w-[10px] sm:hidden rotate-[180deg] bg-white-A700 relative bottom-[-7px]" />
                    </div>
                  </div>
                </div>
              </div>
              <Img
                src="images/img_img_20240216_wa0052.png"
                alt="img20240216_one"
                className="w-[30%] sm:w-[100%] sm:ml-[0px] ml-[21px] object-cover"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full mt-[-10px] bg-[#fffaee] sm:pb-[140px] pb-[100px]">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="flex flex-col items-center justify-start w-full gap-[2168px]">
                <div className="flex flex-col items-start justify-start w-full pt-[83px] sm:pt-[40px] pl-[83px] sm:pl-[14px] sm:pr-[14px] pr-14 bg-yellow-50">
                  <Heading size="md" as="h2" className="ml-[15px]">
                    इतिहासः
                  </Heading>
                  <div className="h-[4px] w-[7%] sm:w-[20%] mt-[11px] ml-4 bg-yellow-900 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[109px] bg-yellow-900 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[118px] bg-yellow-900 rounded-sm" />
                  <Text
                    size="xl"
                    as="p"
                    className="w-[96%] mt-[45px] ml-4 !font-rhodiumlibre !leading-10"
                  >
                    ज्योतिरादित्य सिंधिया, जनसेवा को परम दायित्व मानने वाले और
                    लोक कल्याण हेतु स्वयं को भी होम कर देने से न हिचकने वाले उस
                    सिंधिया परिवार के सदस्य हैं जिसकी एक समृद्ध परंपरा 'जनसेवा'
                    की रही है। सेवा और त्याग की इसी वाटिका से इन नैतिक भावों का
                    पल्लवन श्री ज्योतिरादित्य सिंधिया में भी हुआ। जनसेवा एवं
                    राजनीति को उन्होंने और करीब से तब जाना जब 2001 में लगभग 13
                    वर्ष की उम्र में वे अपने पिता स्व. श्री माधवराव सिंधिया जी
                    के चुनाव प्रचार का हिस्सा बने। ज्योतिरादित्य सिंधिया के जीवन
                    पर उनके पिता की सादगी, जनसेवा के प्रति समर्पण एवं आदर्शों और
                    नैतिक मूल्यों के प्रति प्रतिबद्धता की स्पष्ट छाप दिखाई देती
                    है। इसके साथ ही राजपथ को त्यागकर लोकपथ चुनने वाली, जनसंघ की
                    संस्थापक सदस्य उनकी आजी अम्मा राजमाता विजयराजे सिंधिया जी के
                    सुसंस्कृत जीवन के अंश भी ज्योतिरादित्य सिंधिया जी के
                    व्यक्तित्व में झलकते हैं। समाज के गरीब, वंचित और पीड़ित तबकों
                    की भलाई तथा महिला सशक्तिकरण व समाज के सतत विकास की दृष्टि से
                    राजमाता द्वारा किए गये प्रभावी कार्य एवं जनसेवा हेतु सदैव
                    समर्पित रहा उनका विराट जीवन, सिंधिया परिवार की 'सेवा परंपरा'
                    का प्रतीक भी है और श्री ज्योतिरादित्य सिंधिया जी के लिए
                    प्रेरणा का अक्षय स्रोत भी।
                  </Text>
                  <Text
                    size="xl"
                    as="p"
                    className="w-[96%] mt-[30px] ml-4 !font-rhodiumlibre !leading-10"
                  >
                    वर्ष 1947 में देश की स्वतंत्रता के पश्चात तत्कालीन ग्वालियर
                    राज्य, भारत गणराज्य में सम्मिलित होकर मध्य भारत राज्य
                    (वर्तमान मध्यप्रदेश) का हिस्सा बना जिसका आगे चलकर वर्ष 1956
                    में मध्य प्रदेश में विलय हुआ। उस समय श्री सिंधिया के दादा के
                    दादाजी कैलाशवासी श्रीमंत जीवाजीराव सिंधिया ने क्षेत्र के
                    विकास और प्रगति के लिए कई नवाचारों को अपनाया और इसके साथ ही
                    शिक्षा, महिला सशक्तिकरण, विकास और कृषि के क्षेत्र में
                    निरन्तर उल्लेखनीय कार्य करते हुए ग्वालियर को मध्य भारत के
                    सबसे प्रगतिशील राज्य के रूप में भी स्थापित किया। इसी परिवार
                    के महाराजा माधो राव सिंधिया प्रथम द्वारा 1886 से 1925 तक के
                    अपने कार्यकाल में जनता की भलाई हेतु कई अभूतपूर्व कार्य किए
                    गये जिनके लिए उन्हें 'Maker of Modern Gwalior के नाम से भी
                    जाना जाता है। वे ग्वालियर के उन राजाओं में से एक थे
                    जिन्होंने प्रजा को अपना परिवार मानते हुए जीवन भर उनकी सेवा
                    की एवं समाज के हर वर्ग को क्षेत्र के विकास का सहभागीदार
                    बनाया। ग्वालियर में आधुनिकता और विकासवाद की नींव रखने का
                    श्रेय भी माधो महाराज को ही दिया जाता है जिन्होंने अपने राज्य
                    को एक सतत प्रगतिशील, सुसंपन्न व समृद्ध क्षेत्र के रूप में
                    स्थापित किया। इन्हीं संस्कारों में ढलकर जनसेवा और जनविकास की
                    इस विरासत को आगे बढ़ाते हुए ज्योतिरादित्य सिंधिया ने 2001
                    में न सिर्फ अपने राजनीतिक सफर की शुरुआत की बल्कि जनसेवा को
                    लक्ष्य बनाकर पूरे भारत में अपने विजन और विभिन्न नवाचारों के
                    माध्यम से विकास के नए कीर्तिमान भी स्थापित किए।
                  </Text>
                  {/* <Text
                    size="xl"
                    as="p"
                    className="w-[96%] mt-[30px] ml-4 !font-rhodiumlibre !leading-10"
                  >
                    इन्हीं संस्कारों में ढलकर जनसेवा और लोक कल्याण की इस विरासत
                    को आगे बढ़ाते हुए ज्योतिरादित्य सिंधिया ने 2001 में न सिर्फ
                    अपने राजनीतिक सफर की शुरुआत की बल्कि जनसेवा को लक्ष्य बनाकर
                    पूरे भारत में अधोसंरचना विकास और विभिन्न नवाचारों के माध्यम
                    से विकास के नए कीर्तिमान भी स्थापित किए।
                  </Text> */}
                  <div className="flex mt-[81px] sm:mt-[40px]">
                    <Button
                      color="yellow_900_7f"
                      size="md"
                      className="w-[50px]"
                    >
                      <Img src="images/img_cricket_svgrepo_com.svg" />
                    </Button>
                    <Heading
                      size="md"
                      as="h3"
                      className="ml-[20px] sm:text-[20px]"
                    >
                      क्रिकेट प्रेमी श्री ज्योतिरादित्य सिंधिया
                    </Heading>
                  </div>
                  <div className="h-[4px] w-[7%] mt-[13px] ml-4 bg-yellow-900 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[109px] bg-yellow-900 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[118px] bg-yellow-900 rounded-sm" />
                  <Text
                    size="xl"
                    as="p"
                    className="w-[96%] mt-[30px] ml-4 !font-rhodiumlibre !leading-10"
                  >
                    श्री ज्योतिरादित्य सिंधिया जनता के बीच जनसेवक के बाद अगर
                    किसी उपाधि से जाने जाते है तो वो है 'क्रिकेटप्रेमी'। क्रिकेट
                    से उनका लगाव कई मौकों पर ग्राउंड पर देखने को मिलता रहा है।
                    श्री सिंधिया को यह क्रिकेट प्रेम अपने पिता कैलाशवासी श्रीमंत
                    माधवराव सिंधिया से विरासत के रूप में प्राप्त हुआ था। वे भी
                    क्रिकेट के बेहद शौकीन थे एवं भारतीय क्रिकेट बोर्ड के अध्यक्ष
                    भी रहे थे। अपने पिता की तरह श्री सिंधिया भी क्रिकेट प्रशासक
                    के रूप में मध्यप्रदेश क्रिकेट बोर्ड के अध्यक्ष रहे एवं एक
                    क्रिकेट प्रशासक के रूप में उन्होंने खेल के बुनियादी ढाँचे
                    में सुधार और खिलाड़ियों के भविष्य को सुरक्षित करने के लिए कई
                    आवश्यक एवं महत्त्वपूर्ण कदम उठाए।
                  </Text>
                  <div className="flex mt-[81px] sm:mt-[40px]">
                    <Button
                      color="yellow_900_7f"
                      size="md"
                      className="w-[50px]"
                    >
                      <Img src="images/img_meditation_rela.svg" />
                    </Button>
                    <Heading
                      size="md"
                      as="h3"
                      className="ml-[20px] sm:text-[20px]"
                    >
                      अध्यात्म और धर्म से सरोकार
                    </Heading>
                  </div>
                  <div className="h-[4px] w-[7%] mt-[13px] ml-4 bg-yellow-900 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[109px] bg-yellow-900 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[118px] bg-yellow-900 rounded-sm" />
                  <Text
                    size="xl"
                    as="p"
                    className="w-[96%] mt-[30px] ml-4 !font-rhodiumlibre !leading-10"
                  >
                    एक नेता की आध्यात्मिक आस्था उसके विचारों और कार्यों को
                    प्रेरित करने में महत्वपूर्ण भूमिका निभाती है साथ ही यह आस्था
                    उसे समाजिक परिवर्तन के लिए भी निर्देशित करती है। श्री
                    ज्योतिरादित्य सिंधिया का अध्यात्म, संस्कृति और हिन्दू
                    परमपराओं से गहरा लगाव है। नर सेवा ही नारायण सेवा के वाक्य को
                    चरितार्थ करते हुए वे अध्यात्म को जीने की कला एवं जनसेवा के
                    रूप में देखते है।
                  </Text>
                  {/* <div className="flex flex-row justify-start items-center mt-[46px] ml-4">
                    <Button
                      color="yellow_900_7f"
                      size="md"
                      className="w-[50px]"
                    >
                      <Img src="images/img_cricket_svgrepo_com.svg" />
                    </Button>
                    <Text
                      size="xl"
                      as="p"
                      className="ml-3.5 !font-rhodiumlibre"
                    >
                      क्रिकेटप्रेमी श्री ज्योतिरादित्य सिंधिया
                    </Text>
                    <Button
                      color="yellow_900_7f"
                      size="md"
                      className="w-[50px] ml-[50px]"
                    >
                      <Img src="images/img_meditation_rela.svg" />
                    </Button>
                    <Text
                      size="xl"
                      as="p"
                      className="ml-[15px] !font-rhodiumlibre"
                    >
                      आध्यात्मिकता
                    </Text>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
