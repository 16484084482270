import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import { Text, Img, Button, Heading, Input } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";
import "./index.css";
import { toast } from "react-toastify";
import BaseUrl from "context/BaseUrl";
import axios from "axios";
// import map from "../../assets/images/Component 6.png";
// import mail from "../../assets/images/mail.png";
// import telephone from "../../assets/images/telephone.png";
// import phone from "../../assets/images/phone.png";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function handlePostNumber() {
    if (
      formData?.name === "" &&
      formData?.email === "" &&
      formData?.phoneNumber === "" &&
      formData?.subject
    ) {
      toast.error("field is required!");
      return;
    }
    const raw = JSON.stringify({
      Name: formData?.name,
      Email: formData?.email,
      PhoneNumber: formData?.phoneNumber,
      Subject: formData?.subject,
    });
    try {
      const response = await axios({
        method: "post",
        url: BaseUrl + "Api/ContactUs",
        data: raw,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 201) {
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          subject: "",
        });
      }
      toast.success("Contact Form Post successful");
    } catch (error) {
      toast.error("Error while making post request");
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <div className="contact_page">
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <Header />
      <div className="contact-section">
        <div className="contact_div">
          <div className="left_div">
            <h1>
              Get in <span>Touch</span>
            </h1>
            {/* <p>
              Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu
              leo molestie vel, ornare non id blandit netus.
            </p> */}
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData?.name}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Email"
              name="email"
              value={formData?.email}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Phone number"
              name="phoneNumber"
              value={formData?.phoneNumber}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={10}
              onChange={handleChange}
            />
            <input
              type="text"
              placeholder="Subject"
              name="subject"
              value={formData?.subject}
              onChange={handleChange}
            />
            <button className="send-btn" onClick={handlePostNumber}>
              SEND
            </button>
            {/* <div className="flex contact_details">
              <div className="details_div">
                <img src={phone} />
                <div className="contact_here">
                  <h4>Phone</h4>
                  <span>03 5432 1234</span>
                </div>
              </div>
              <div className="details_div">
                <img src={telephone} />
                <div className="contact_here">
                  <h4>Phone</h4>
                  <span>03 5432 1234</span>
                </div>
              </div>
              <div className="details_div">
                <img src={mail} />
                <div className="contact_here">
                  <h4>Phone</h4>
                  <span>03 5432 1234</span>
                </div>
              </div>
            </div> */}
          </div>
          <div className="right_div">
            {/* <img src={map} /> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.254685342382!2d77.2117284!3d28.5921354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce291629de08d%3A0x159171b709106d3f!2s27%2C%20Safdarjung%20Rd%2C%20Tuglak%20Crescent%20Area%2C%20Tughlak%20Road%20Area%2C%20New%20Delhi%2C%20Delhi%20110011!5e0!3m2!1sen!2sin!4v1709796584607!5m2!1sen!2sin"
              width="400px"
              height="500px"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="backgroud_div"></div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
