import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import DesktopNine from "pages/DesktopNine";
import DesktopTwelve from "pages/DesktopTwelve";
import DesktopSeven from "pages/DesktopSeven";
import DesktopEight from "pages/DesktopEight";
import DesktopEleven from "pages/DesktopEleven";
import Podcast from "pages/Podcast";
import Contact from "pages/ContactUS/Contact";
import Admin from "pages/Admin";
import GalleryPage from "pages/DesktopEight/Gallery";
import Vikas from "pages/DesktopEleven/Vikas";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    {
      path: "/parichay",
      element: <DesktopNine />,
    },
    {
      path: "/",
      element: <DesktopTwelve />,
    },
    {
      path: "/vade-irade",
      element: <DesktopSeven />,
    },
    {
      path: "/admin",
      element: <Admin />,
    },
    {
      path: "/press",
      element: <DesktopEight />,
    },
    {
      path: "/gallery",
      element: <GalleryPage />,
    },
    {
      path: "/podcast",
      element: <Podcast />,
    },
    {
      path: "/work-done",
      element: <DesktopEleven />,
    },
    {
      path: "/vikas",
      element: <Vikas />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
