import { Img } from "components";
import React from "react";
import Slider from "react-slick";

function Gallery() {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          adaptiveHeight:true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          adaptiveHeight:true,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <Img
            src="images/img_rectangle_119.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_118.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_120.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_121.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_122.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_119.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_118.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_120.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_121.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/img_rectangle_122.png"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/image1.jpg"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/image2.jpg"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/image3.jpg"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
        <div>
          <Img
            src="images/image4.jpg"
            alt="rectangle"
            className="w-[100%] sm:h-[250px] h-[372px] object-cover rounded"
          />
        </div>
      </Slider>
    </>
  );
}

export default Gallery;
