import React from "react";
import Slider from "react-slick";
// import bannerImg from "../../assets/banner/bannerImage.jpg";
import bannerImg1 from "../../assets/banner/bannerImage.jpg";
import bannerImg2 from "../../assets/banner/banner1.jpg";
import bannerImg3 from "../../assets/banner/banner3.jpg";
import bannerImg4 from "../../assets/banner/banner4.jpg";
// import bannerImg2 from "../../assets/banner/banner2.jpg";
// import bannerImg3 from "../../assets/banner/banner3.jpg";
// import bannerImg4 from "../../assets/banner/banner4.jpg";
// import bannerImg5 from "../../assets/banner/banner5.jpg";
// import bannerImg6 from "../../assets/banner/banner6.jpg";
// import bannerImg7 from "../../assets/banner/banner7.jpg";
// import bannerImg8 from "../../assets/banner/banner8.jpg";
// import bannerImg9 from "../../assets/banner/banner9.jpg";

function HeroSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    slidesToScroll: 1,
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <img src={bannerImg1} className="w-full object-cover" alt="" />
          {/* <div className="banner-1"> */}
          {/* <Img
              src="images/img_image_removebg_preview.png"
              alt="imageremovebg"
              className="h-[478px] sm:h-[140px] object-cover"
            />
            <div className="flex flex-col items-start justify-start gap-[22px]">
              <Heading
                size="md"
                as="h1"
                className="!leading-[58px] md:!leading-[20px] md:text-[16px]"
              >
                &quot;मैं एक महत्वाकांक्षी राजनीतिज्ञ नहीं लेकिन एक
                महत्वाकांक्षी जन सेवक हूँ&quot;
              </Heading>
              <div className="flex flex-row justify-center items-start w-[30%]">
                <div className="h-[4px] w-[156px] bg-green-A700 rounded-sm" />
                <div className="h-[4px] w-[7px] ml-1 bg-green-A700 rounded-sm" />
                <div className="h-[4px] w-[7px] ml-0.5 bg-green-A700 rounded-sm" />
                <div className="flex w-[57px] ml-[9px]" />
              </div>
              <Text
                size="3xl"
                as="p"
                className="w-[75%] md:w-[100%] !font-rhodiumlibre !font-normal !leading-[45px] md:text-[14px] md:!leading-[20px]"
              >
                &quot;जीवन का लक्ष्य सदैव जनसेवा होना चाहिए, राजनीति सिर्फ एक
                माध्यम&quot;
              </Text>
            </div> */}
          {/* </div> */}
        </div>
        <div>
          <img src={bannerImg2} className="w-full object-cover" alt="" />
          {/* <div className="banner-4"></div> */}
        </div>
        <div>
          {/* <img src={bannerImg} className="banner-1" alt="" /> */}
          <img src={bannerImg3} className="w-full object-cover" alt="" />
          {/* <div className="banner-2"> */}
          {/* <Img
              src="images/hero-img-2.png"
              alt="imageremovebg"
              className="h-[478px] sm:h-[140px] object-cover"
            />
            <div className="flex flex-col items-start justify-start gap-[22px]">
              <Heading
                size="md"
                as="h1"
                className="!leading-[58px] md:!leading-[20px] md:text-[16px]"
              >
                &quot;मैं एक महत्वाकांक्षी राजनीतिज्ञ नहीं लेकिन एक
                महत्वाकांक्षी जन सेवक हूँ&quot;
              </Heading>
              <div className="flex flex-row justify-center items-start w-[30%]">
                <div className="h-[4px] w-[156px] bg-green-A700 rounded-sm" />
                <div className="h-[4px] w-[7px] ml-1 bg-green-A700 rounded-sm" />
                <div className="h-[4px] w-[7px] ml-0.5 bg-green-A700 rounded-sm" />
                <div className="flex w-[57px] ml-[9px]" />
              </div>
              <Text
                size="3xl"
                as="p"
                className="w-[75%] md:w-[100%] !font-rhodiumlibre !font-normal !leading-[45px] md:text-[14px] md:!leading-[20px]"
              >
                &quot;यह जनता नहीं मेरा परिवार है, इनके विकास के लिए कार्य करना
                मेरा धर्म है.&quot;
              </Text>
            </div> */}
          {/* </div> */}
        </div>
        {/*<div>
          <div className="banner-3"> */}
        {/* <Img
              src="images/hero-img-2.png"
              alt="imageremovebg"
              className="h-[478px] sm:h-[140px] object-cover"
            />
            <div className="flex flex-col items-start justify-start gap-[22px]">
              <Heading
                size="md"
                as="h1"
                className="!leading-[58px] md:!leading-[20px] md:text-[16px]"
              >
                &quot;मैं एक महत्वाकांक्षी राजनीतिज्ञ नहीं लेकिन एक
                महत्वाकांक्षी जन सेवक हूँ&quot;
              </Heading>
              <div className="flex flex-row justify-center items-start w-[30%]">
                <div className="h-[4px] w-[156px] bg-green-A700 rounded-sm" />
                <div className="h-[4px] w-[7px] ml-1 bg-green-A700 rounded-sm" />
                <div className="h-[4px] w-[7px] ml-0.5 bg-green-A700 rounded-sm" />
                <div className="flex w-[57px] ml-[9px]" />
              </div>
              <Text
                size="3xl"
                as="p"
                className="w-[75%] md:w-[100%] !font-rhodiumlibre !font-normal !leading-[45px] md:text-[14px] md:!leading-[20px]"
              >
                &quot;यह जनता नहीं मेरा परिवार है, इनके विकास के लिए कार्य करना
                मेरा धर्म है.&quot;
              </Text>
            </div> */}
        {/* </div>
        </div> */}
        <div>
          <img src={bannerImg4} className="w-full object-cover" alt="" />
          {/* <div className="banner-4"></div> */}
        </div>
      </Slider>
    </>
  );
}

export default HeroSlider;
