import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Heading } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";
import pdfImg from "../../assets/jpg/pdf-img.jpg";
import downloadPdf from "../../assets/banner/HMCA_pamphlet-Final.pdf";

export default function Vikas() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const downloadPDF = () => {
    const pdfUrl = downloadPdf;

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", "HMCA_pamphlet-Final.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start overflow-auto">
        <Header />
        <div className="flex flex-row justify-center w-full mt-[19px] p-5 rounded-tl-[35px] rounded-tr-[35px] border-yellow-900 border-t-2 border-solid bg-gradient">
          <div className="flex flex-row justify-center w-[39%] sm:w-full mb-1">
            <Heading
              size="xl"
              className="sm:text-[24px] sm:leading-[32px]"
              as="h1"
            >
              विकास कार्य
            </Heading>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start px-0 sm:px-[16px] w-full mt-[65px] max-w-[1240px]">
          {/* <Text size="5xl" as="p" className="ml-px text-center sm:text-left">
            विकास कार्य
          </Text>
          <div className="h-[4px] w-[13%] mt-3.5 ml-px bg-green-A700 rounded-sm" />
          <div className="h-[4px] w-[1%] mt-[-4px] ml-40 bg-green-A700 rounded-sm" />
          <div className="h-[4px] w-[1%] mt-[-4px] ml-[169px] bg-green-A700 rounded-sm" /> */}
          <div className="mt-[56px] pb-[140px]">
            <div className="grid grid-cols-3">
              <img
                src={pdfImg}
                onClick={downloadPDF}
                className="cursor-pointer"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
