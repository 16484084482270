import React from 'react'
import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import Header from "../../components/Header";
import Footer from 'components/footer/Footer';
import "./index.css"

function index() {
  
  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex flex-col items-center justify-start">
        <div className="flex flex-col items-center justify-start w-full overflow-auto">
          <Header />
          <div className="h-[538px] sm:h-[200px] w-full mt-[19px] relative">
            <Img
              src="images/podcastImage.png"
              alt="image"
              className="justify-center sm:h-[200px] h-[538px] w-full left-0 bottom-0 right-0 top-0 m-auto rounded-tr-[35px] rounded-tl-[35px] object-cover absolute"
            />
            <div className="flex flex-row justify-center w-full h-full pt-[100px] left-0 bottom-0 right-0 top-0 px-14 m-auto rounded-tl-[35px] rounded-tr-[35px] bg-gray-900_7c absolute">
              <div className="flex flex-row justify-between items-center w-full mt-[22px] max-w-[1240px]">
                <Heading size="2xl" as="h1" className="!text-white-A700 text-shadow-ts sm:text-[30px] sm:leading-[32px]">
                  Podcasts
                </Heading>

              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full  max-w-[1240px] podcast_div">


            <div className="w-full mt-[49px] gap-[30px] grid-cols-2 grid podcast_div_flex">
              <div className="flex flex-col items-center justify-start w-full gap-3.5">
                <video controls width="100%">
                  <source src={"https://youtu.be/gkAVCFOjZfA?si=Cqnw0GvWPMRw2Ppv"} type="video/mp4" />
                </video>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <a href="">
                    <Text size="3xl" as="p">
                      Lallantop
                    </Text>
                  </a>
                  <a href="" className="!leading-7">
                    <Text size="md" as="p" className="!text-black-900_cc !font-outfit !font-normal underline">
                      Link: https://www.youtube.com/watch?v=maDlGagoF-I
                    </Text>
                  </a>
                </div>
              </div>
              <div className="flex flex-col items-center justify-start w-full gap-3.5">
                <video controls width="100%">
                  <source src={"https://youtu.be/gkAVCFOjZfA?si=Cqnw0GvWPMRw2Ppv"} type="video/mp4" />
                </video>
                <div className="flex flex-col items-start justify-start w-full gap-1">
                  <a href="#">
                    <Text size="3xl" as="p">
                      ANI
                    </Text>
                  </a>
                  <a href="#" className="!leading-7">
                    <Text size="md" as="p" className="!text-black-900_cc !font-outfit !font-normal underline">
                      Link: https://www.youtube.com/watch?v=djLqMZjJtkc
                    </Text>
                  </a>
                </div>
              </div>
            </div>

          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default index