import { Heading, Img, Text } from "components";
import React from "react";
import Slider from "react-slick";

function Media() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-5"
              href="https://www.news18.com/opinion/opinion-scindias-and-indias-steel-industry-a-tale-of-tradition-innovation-and-leadership-8848143.html"
            >
              <Img
                src="images/union-ministe.webp"
                alt="image"
                className="w-full h-[262px] object-cover"
              />
              <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                <Heading size="s" as="h3" className="!font-alegreya">
                  Opinion | Scindias and India’s Steel Industry: A Story of
                  Tradition, Innovation and Leadership
                </Heading>
                <a
                  href="https://www.news18.com/opinion/opinion-scindias-and-indias-steel-industry-a-tale-of-tradition-innovation-and-leadership-8848143.html"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.news18.com/opinion/opinion-scindias-and-indias-steel-industry-a-tale-of-tradition-innovation-and-leadership-8848143.html
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-5"
              href="https://thedailyguardian.com/guna-lok-sabha-constituency-and-scindias/"
            >
              <Img
                src="images/Jyotiraditya-M.jpg"
                alt="image"
                className="w-full h-[262px] object-cover"
              />
              <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                <Heading size="s" as="h3" className="!font-alegreya">
                  Guna Lok Sabha constituency and Scindias
                </Heading>
                <a
                  href="https://thedailyguardian.com/guna-lok-sabha-constituency-and-scindias/"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://thedailyguardian.com/guna-lok-sabha-constituency-and-scindias/
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-5"
              href="https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html"
            >
              <Img
                src="images/indiaimg.webp"
                alt="image"
                className="w-full h-[262px] object-cover"
              />
              <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                <Heading size="s" as="h3" className="!font-alegreya">
                  A giant leap in India’s development journey
                </Heading>
                <a
                  href="https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-5"
              href="https://organiser.org/2024/01/21/217178/opinion/ram-mandir-and-rajmata-scindia-the-inseparable-legacy/#google_vignette"
            >
              <Img
                src="images/Rectangle15.png"
                alt="image"
                className="w-full object-cover"
              />
              <div className="flex flex-col items-center justify-start w-[97%] gap-px">
                <Heading size="s" as="h3" className="!font-alegreya">
                  Ram Mandir and Rajmata Scindia: The Inseparable Legacy 
                </Heading>
                <a
                  href="https://organiser.org/2024/01/21/217178/opinion/ram-mandir-and-rajmata-scindia-the-inseparable-legacy/#google_vignette"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://organiser.org/2024/01/21/217178/opinion/ram-mandir-and-rajmata-scindia-the-inseparable-legacy/#google_vignette
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-3.5"
              href="https://www.livehindustan.com/blog/story-hindustan-opinion-column-development-of-the-deprived-will-continuously-7-december-2023-9033993.html"
            >
              <Img
                src="images/union.jpg"
                alt="image"
                className="w-full h-[262px] object-cover"
              />
              <div className="flex flex-col items-start justify-start w-full gap-1">
                <Text size="3xl" as="p">
                  अनवरत जारी रहेगा वंचितों का विकास
                </Text>
                <a
                  href="https://www.livehindustan.com/blog/story-hindustan-opinion-column-development-of-the-deprived-will-continuously-7-december-2023-9033993.html"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.livehindustan.com/blog/story-hindustan-opinion-column-development-of-the-deprived-will-continuously-7-december-2023-9033993.html
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-5"
              href="https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html"
            >
              <Img
                src="images/India.avif"
                alt="image"
                className="w-full h-[262px] object-cover"
              />
              <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                <Heading size="s" as="h3" className="!font-alegreya">
                  India is making its mark in the world
                </Heading>
                <a
                  href="https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
        <div>
          <div>
            <a
              className="flex flex-col items-center justify-start w-full gap-5"
              href="https://www.youtube.com/watch?v=_Pv1-OifwdQ"
            >
              <Img
                src="images/img_rectangle_154.png"
                alt="image"
                className="w-full h-[262px] object-cover"
              />
              <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                <Heading size="s" as="h3" className="!font-alegreya">
                  Interview with India Today on the future of flying cars, civil
                  aviation growth & more.
                </Heading>
                <a
                  href="https://www.youtube.com/watch?v=_Pv1-OifwdQ"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.youtube.com/watch?v=_Pv1-OifwdQ
                  </Text>
                </a>
              </div>
            </a>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default Media;
