import React from "react";
import DesktopTwelvePage from "./DesktopTwelve";


const Home = () => {
  return (
    <>
      <DesktopTwelvePage />
    </>
  );
};
export default Home;
