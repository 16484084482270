import axios from "axios";
import Header from "components/Header";
import Footer from "components/footer/Footer";
import BaseUrl from "context/BaseUrl";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// import { Text, Img, Button, Heading, Input } from "../../components";
// import { toast } from "react-toastify";
// import map from "../../assets/images/Component 6.png";
// import mail from "../../assets/images/mail.png";
// import telephone from "../../assets/images/telephone.png";
// import phone from "../../assets/images/phone.png";

function Admin() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [contact, setContact] = useState([]);
  async function getContact() {
    try {
      const response = await axios({
        method: "get",
        url: BaseUrl + "Api/GetallContactUs",
      });
      if (response?.status === 200) {
        setContact(response?.data);
      }
      //   toast.success("Contact Form Post successful");
    } catch (error) {
      //   toast.error("Error while making post request");
    }
  }

  useEffect(() => {
    getContact();
  }, []);

  return (
    <div className="contact_page">
      <Helmet>
        <title>Admin</title>
        <meta name="admin" content="Web site created using create-react-app" />
      </Helmet>
      <Header />
      <div className="contact-section">
      <h6 className="text-center text-[34px] mb-[40px]">Contact Form Table</h6>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                  Subject
                </th>
              </tr>
            </thead>
            <tbody>
              {contact &&
                contact.map((e, i) => {
                  return (
                    <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {e?.Name}
                      </th>
                      <td className="px-6 py-4">{e?.Email}</td>
                      <td className="px-6 py-4">{e?.PhoneNumber}</td>
                      <td className="px-6 py-4">{e?.Subject}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Admin;
