import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";

export default function DesktopElevenPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const sectionId = hash.substring(1);
      scrollToSection(sectionId);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start overflow-auto">
        <Header />
        <div className="flex flex-row justify-center w-full mt-[19px] p-5 rounded-tl-[35px] rounded-tr-[35px] border-yellow-900 border-t-2 border-solid bg-gradient">
          <div className="flex flex-row justify-center w-[39%] sm:w-full mb-1">
            <Heading
              size="xl"
              className="sm:text-[24px] text-center sm:leading-[32px]"
              as="h1"
            >
              विमान क्षेत्र की ऊँची उड़ान
            </Heading>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start px-0 sm:px-[16px] w-full mt-[65px] max-w-[1240px]">
          <Text size="5xl" as="p" className="ml-px text-center sm:text-left">
            विमानन क्षेत्र की ऊँची उड़ान
          </Text>
          <div className="h-[4px] w-[13%] mt-3.5 ml-px bg-green-A700 rounded-sm" />
          <div className="h-[4px] w-[1%] mt-[-4px] ml-40 bg-green-A700 rounded-sm" />
          <div className="h-[4px] w-[1%] mt-[-4px] ml-[169px] bg-green-A700 rounded-sm" />
          <div className="flex flex-row justify-center w-full mt-[51px] p-6 border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
            <div className="flex flex-row sm:flex-col justify-between items-start w-full">
              <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                <div className="h-[395px] w-full relative">
                  <Img
                    src="images/image001JA5Y.jpg"
                    alt="image"
                    className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                  />
                  <div className="flex flex-row justify-start items-start w-[96%] gap-[9px] bottom-[2%] right-0 left-0 p-[7px] m-auto bg-white-A700 absolute rounded-lg">
                    <Text
                      size="md"
                      as="p"
                      className="flex justify-center items-center h-[38px] w-[38px] mb-px ml-px text-center bg-orange-300 rounded-[50%]"
                    >
                      01
                    </Text>
                    <Text size="md" as="p" className="mt-1.5 text-center">
                      डिजियात्रा :
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start sm:w-full w-[66%] mt-[18px]">
                <Text size="xl" as="p" className="!font-medium">
                  डिजियात्रा:
                </Text>
                <div className="flex flex-row justify-start w-[12%] mt-[9px] gap-0.5">
                  <div className="h-[4px] w-[81%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                </div>
                <Text
                  size="xl"
                  as="p"
                  className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                >
                  चेहरा पहचान प्रणाली (FRT) के आधार पर हवाई अड्डों पर यात्रियों
                  की हवाई यात्रा को और सुलभ, संपर्क रहित व पेपरलैस बनाने हेतु
                  डिजियात्रा सर्विस की शुरुआत 2022 में की गयी थी। आज देश के 13
                  हवाईअड्डों पर घरेलु यात्रियों के लिए डिजियात्रा सर्विस उपलब्ध
                  है जिसमें दिल्ली, वाराणसी, बेंगलुरु, हैदराबाद, कोलकाता, पुणे,
                  विजयवाड़ा, कोचीन, मुंबई, अहमदाबाद, लखनऊ, गुवाहाटी और जयपुर
                  शामिल है। फरवरी 2024 तक इस सुविधा का इस्तेमाल करने वाले
                  यात्रियों की संख्या बढ़कर 45.8 लाख हुई है और डिजियात्रा से
                  यात्रा करने वाले यात्रियों की संख्या 1.45 करोड़ तक पहुंच गई
                  है। श्री सिंधिया जी का मिशन है कि इस सुविधा का विस्तार देश के
                  सभी हवाईअड्डों पर किया जाए।
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full mt-[30px] p-[23px] border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
            <div className="flex flex-row sm:flex-col-reverse justify-between items-start w-full">
              <div className="flex flex-col items-start justify-start w-[63%] sm:w-full mt-[19px]">
                <Text size="xl" as="p" className="!font-medium">
                  विमान ईंधन (Aircraft Fuel) पर वैट में कमी:
                </Text>
                <div className="flex flex-row justify-start w-[13%] mt-2 gap-0.5">
                  <div className="h-[4px] w-[81%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                </div>
                <Text
                  size="xl"
                  as="p"
                  className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                >
                  देश के विभिन्न शहरों में हवाई संपर्क की सुविधा के विस्तार हेतु
                  ATF पर लगने वाले वैट दरों को कम करने की नितांत आवश्यकता थी।
                  जिसे समझकर श्री सिंधिया जी के नेतृत्व में 16 प्रदेशों और
                  केंद्र शासित प्रदेशों ने अपनी वैट दरों को 20% से कम की श्रेणी
                  में किया है जिससे देशभर के शहरों में एयर कनेक्टिविटी की सुविधा
                  का विस्तार हुआ है।
                </Text>
              </div>
              <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                <div className="h-[395px] w-full relative">
                  <Img
                    src="images/Formation.jpg"
                    alt="image_one"
                    className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                  />
                  <div className="flex flex-row justify-start items-center w-[96%] gap-[15px] bottom-[2%] right-0 left-0 m-auto bg-white-A700 absolute rounded-lg">
                    <Text
                      size="md"
                      as="p"
                      className="flex justify-center items-center h-[38px] w-[38px] ml-[7px] text-center bg-orange-300 rounded-[50%]"
                    >
                      02
                    </Text>
                    <Text size="md" as="p" className="w-[82%] mt-[3px]">
                      विमान ईंधन (Aircraft Fuel) पर वैट में कमी:
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full mt-[30px] p-6 border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
            <div className="flex flex-row sm:flex-col justify-between items-start w-full">
              <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                <div className="h-[395px] w-full relative">
                  <Img
                    src="images/Prime-Minister.jpg"
                    alt="image_two"
                    className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                  />
                  <div className="flex flex-row justify-start items-center w-[96%] gap-3.5 bottom-[2%] right-0 left-0 p-[7px] m-auto bg-white-A700 absolute rounded-lg">
                    <Text
                      size="md"
                      as="p"
                      className="flex justify-center items-center h-[38px] w-[38px] mb-px ml-px text-center bg-orange-300 rounded-[50%]"
                    >
                      03
                    </Text>
                    <Text size="md" as="p" className="text-center">
                      हवाई अड्डों की संख्या हुई दुगुनी :
                    </Text>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start sm:w-full w-[66%] mt-[21px]">
                <Text size="xl" as="p" className="!font-medium">
                  हवाई अड्डों की संख्या हुई दुगुनी :
                </Text>
                <div className="flex flex-row justify-start w-[12%] mt-1.5 gap-0.5">
                  <div className="h-[4px] w-[81%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                </div>
                <Text
                  size="xl"
                  as="p"
                  className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                >
                  देश की आमजनता के लिए भी हवाई यात्रा की उपलब्धता सुनिश्चित हो
                  तथा शहरों की एयर कनेक्टिविटी बढ़े इस हेतु प्रधानमंत्री श्री
                  नरेंद्र मोदी जी के नेतृत्व में श्री सिंधिया जी ने नवीन
                  हवाईअड्डों के निर्माण पर जोर दिया है और इसी का परिणाम है कि
                  2014 में जहाँ देश में सिर्फ 74 हवाईअड्डे थे वहीं आज यह संख्या
                  दुगुनी होकर 149 हो गयी है।
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full mt-[30px] p-[23px] border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
            <div className="flex flex-row sm:flex-col-reverse justify-between items-start w-full">
              <div className="flex flex-col items-start justify-start sm:w-full w-[63%] mt-5">
                <Text size="xl" as="p" className="!font-medium">
                  सुदूर क्षेत्रों में बढ़ी कनेक्टिविटी:
                </Text>
                <div className="flex flex-row justify-start w-[13%] mt-[7px] gap-0.5">
                  <div className="h-[4px] w-[81%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                </div>
                <Text
                  size="xl"
                  as="p"
                  className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                >
                  प्रधानमंत्री श्री नरेंद्र मोदी जी के 'सब उड़ें, सब जुड़ें' के
                  संकल्प को आगे बढ़ाते हुए उड़ान योजना के अंतर्गत पुरे देश में
                  75 हवाईअड्डों का निर्माण हुआ है तथा 519 का सुव्यवस्थित संचालन
                  किया जा रहा है।
                </Text>
              </div>
              <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                <div className="h-[395px] w-full relative">
                  <Img
                    src="images/img_rectangle_159_2.png"
                    alt="image_three"
                    className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                  />
                  <div className="flex flex-row justify-start items-center w-[96%] gap-3.5 bottom-[2%] right-0 left-0 p-[7px] m-auto bg-white-A700 absolute rounded-lg">
                    <Text
                      size="md"
                      as="p"
                      className="flex justify-center items-center h-[38px] w-[38px] mt-px ml-px text-center bg-orange-300 rounded-[50%]"
                    >
                      04
                    </Text>
                    <Text size="md" as="p">
                      सुदूर क्षेत्रों में बढ़ी कनेक्टिविटी:
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row sm:flex-col justify-between items-start w-full mt-[30px] p-6 border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
            <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
              <div className="h-[395px] w-full relative">
                <Img
                  src="images/img_rectangle_159_3.png"
                  alt="image_four"
                  className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                />
                <div className="flex flex-row justify-start items-start w-[96%] gap-[18px] bottom-[2%] right-0 left-0 p-[7px] m-auto bg-white-A700 absolute rounded-lg">
                  <Text
                    size="md"
                    as="p"
                    className="flex justify-center items-center h-[38px] w-[38px] mb-px ml-px text-center bg-orange-300 rounded-[50%]"
                  >
                    05
                  </Text>
                  <Text size="md" as="p" className="mt-1.5 text-center">
                    एविएशन हब का निर्माण :
                  </Text>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start sm:w-full w-[66%] mt-[18px]">
              <Text size="xl" as="p" className="!font-medium">
                एविएशन हब का निर्माण :
              </Text>
              <div className="h-[4px] w-[10%] mt-[9px] ml-px bg-orange-200 rounded-sm" />
              <div className="h-[4px] w-[1%] mt-[-4px] ml-[76px] bg-orange-200 rounded-sm" />
              <div className="h-[4px] w-[1%] mt-[-4px] ml-[85px] bg-orange-200 rounded-sm" />
              <Text
                size="xl"
                as="p"
                className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
              >
                विमानन क्षेत्र में आत्मनिर्भर बनने और इस क्षेत्र में विकास के नए
                अवसर उत्पन्न करने के लिए देश में पहले एविएशन हब के महत्वपूर्ण
                लक्ष्य पर श्री सिंधिया जी के नेतृत्व में कार्य किया जा रहा है।
                श्री सिंधिया जी क्षेत्र के सभी हितधारकों के साथ मिलकर दिल्ली में
                भारत के पहले एविएशन हब के निर्माण हेतु संकल्पबद्ध हैं। इस एविएशन
                हब से न सिर्फ अन्य देशों पर हमारी निर्भरता कम होगी बल्कि
                आत्मनिर्भरता की दिशा में आगे बढ़ते हुए हमारा विमानन क्षेत्र देश
                की अर्थव्यवस्था को भी अधिक मजबूती प्रदान करेगा।
              </Text>
            </div>
          </div>
          <div id="ispat">
            <div className="flex flex-col items-start justify-start">
              <Text
                size="5xl"
                as="p"
                className="mt-[140px] sm:mt-[40px] sm:text-left text-center"
              >
                इस्पात क्षेत्र को मिली नई मजबूती
              </Text>
            </div>
            <div className="h-[4px] w-[13%] mt-3 ml-px bg-green-A700 rounded-sm" />
            <div className="h-[4px] w-[1%] mt-[-4px] ml-40 bg-green-A700 rounded-sm" />
            <div className="h-[4px] w-[1%] mt-[-4px] ml-[169px] bg-green-A700 rounded-sm" />
            <div className="flex flex-col w-full mt-[58px] gap-[30px] pb-[182px]">
              <div className="flex flex-row sm:flex-col justify-between items-start w-full mt-[30px] p-6 border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
                <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                  <div className="h-[395px] w-full relative">
                    <Img
                      src="images/steel1.jpg"
                      alt="image_four"
                      className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                    />
                    <div className="flex flex-row justify-start items-start w-[96%] gap-[18px] bottom-[2%] right-0 left-0 p-[7px] m-auto bg-white-A700 absolute rounded-lg">
                      <Text
                        size="md"
                        as="p"
                        className="flex justify-center items-center h-[38px] w-[38px] mb-px ml-px text-center bg-orange-300 rounded-[50%]"
                      >
                        01
                      </Text>
                      <Text size="md" as="p" className="mt-1.5 text-center">
                        हरित इस्पात:
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start sm:w-full w-[66%] mt-[18px]">
                  <Text size="xl" as="p" className="!font-medium">
                    हरित इस्पात:
                  </Text>
                  <div className="h-[4px] w-[10%] mt-[9px] ml-px bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[76px] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[85px] bg-orange-200 rounded-sm" />
                  <Text
                    size="xl"
                    as="p"
                    className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                  >
                    इस्पात क्षेत्र को सस्टेनेबल और हरित बनाने के लक्ष्य के साथ
                    मंत्री सिंधिया ने 13 टास्क फाॅर्स बनाए हैं जिसमे मंत्रालय के
                    अधिकारियों के साथ, इस्पात क्षेत्र से जुड़े अन्य हितग्राहियों
                    एवं शिक्षा संस्थानों के प्रतिनिधि भी शामिल है। 
                  </Text>
                </div>
              </div>
              <div className="flex flex-row justify-center w-full mt-[30px] p-[23px] border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
                <div className="flex flex-row sm:flex-col-reverse justify-between items-start w-full">
                  <div className="flex flex-col items-start justify-start sm:w-full w-[63%] mt-5">
                    <Text size="xl" as="p" className="!font-medium">
                      भारत वैश्विक स्तर पर इस्पात का दूसरा सबसे बड़ा उत्पादक बन
                      गया है:
                    </Text>
                    <div className="flex flex-row justify-start w-[13%] mt-[7px] gap-0.5">
                      <div className="h-[4px] w-[81%] bg-orange-200 rounded-sm" />
                      <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                      <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                    </div>
                    <Text
                      size="xl"
                      as="p"
                      className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                    >
                      पिछले कुछ वर्षों में इस्पात क्षेत्र में अभूतपूर्व वृद्धि
                      देखी गई है। भारत 2018 में जापान को पछाड़कर दुनिया में
                      इस्पात का दूसरा सबसे बड़ा उत्पादक बन गया और वर्ल्ड स्टील
                      एसोसिएशन द्वारा जारी अनंतिम आंकड़ों के अनुसार 2023 में भी
                      इस स्थिति को बरकरार रखा है। भारत 2003 से हर साल दुनिया का
                      सबसे बड़ा स्पंज आयरन उत्पादक रहा है।
                    </Text>
                  </div>
                  <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                    <div className="h-[395px] w-full relative">
                      <Img
                        src="images/banner/item2.jpeg"
                        alt="image_one"
                        className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[96%] gap-[15px] bottom-[2%] right-0 left-0 m-auto bg-white-A700 absolute rounded-lg">
                        <Text
                          size="md"
                          as="p"
                          className="flex justify-center items-center h-[38px] w-[38px] ml-[7px] text-center bg-orange-300 rounded-[50%]"
                        >
                          02
                        </Text>
                        <Text size="md" as="p" className="w-[82%] mt-[3px]">
                          भारत वैश्विक स्तर पर इस्पात का दूसरा सबसे बड़ा उत्पादक
                          बन गया है:
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row sm:flex-col justify-between items-start w-full mt-[30px] p-6 border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
                <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                  <div className="h-[395px] w-full relative">
                    <Img
                      src="images/banner/item3.jpeg"
                      alt="image_four"
                      className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                    />
                    <div className="flex flex-row justify-start items-start w-[96%] gap-[18px] bottom-[2%] right-0 left-0 p-[7px] m-auto bg-white-A700 absolute rounded-lg">
                      <Text
                        size="md"
                        as="p"
                        className="flex justify-center items-center h-[38px] w-[38px] mb-px ml-px text-center bg-orange-300 rounded-[50%]"
                      >
                        03
                      </Text>
                      <Text size="md" as="p" className="mt-1.5 text-start">
                        इस्पात क्षेत्र के उत्पादों की लेबलिंग और ब्रांडिंग:
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start sm:w-full w-[66%] mt-[18px]">
                  <Text size="xl" as="p" className="!font-medium">
                    इस्पात क्षेत्र के उत्पादों की लेबलिंग और ब्रांडिंग:
                  </Text>
                  <div className="h-[4px] w-[10%] mt-[9px] ml-px bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[76px] bg-orange-200 rounded-sm" />
                  <div className="h-[4px] w-[1%] mt-[-4px] ml-[85px] bg-orange-200 rounded-sm" />
                  <Text
                    size="xl"
                    as="p"
                    className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                  >
                    वैश्विक बाजार में &#39;मेड-इन-इंडिया&#39; स्टील उत्पादों की
                    ब्रांडिंग और लेबलिंग शुरू करने के लिए इस्पात मंत्रालय और
                    वाणिज्य एवं उद्योग मंत्रालय द्वारा ने एक पहल की शुरुआत की
                    है। भारतीय इस्पात उत्पादों को खरीदारों के लिए अधिक आकर्षक
                    बनाने के अलावा, इससे माल की गुणवत्ता भी सुनिश्चित होगी।
                  </Text>
                </div>
              </div>
              <div className="flex flex-row justify-center w-full mt-[30px] p-[23px] border-gray-300 border border-solid bg-gray-50_01 rounded-[16px]">
                <div className="flex flex-row sm:flex-col-reverse justify-between items-start w-full">
                  <div className="flex flex-col items-start justify-start sm:w-full w-[63%] mt-5">
                    <Text size="xl" as="p" className="!font-medium">
                      प्रोडक्शन लिंक्ड इंसेंटिव (पीएलआई) योजना:
                    </Text>
                    <div className="flex flex-row justify-start w-[13%] mt-[7px] gap-0.5">
                      <div className="h-[4px] w-[81%] bg-orange-200 rounded-sm" />
                      <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                      <div className="h-[4px] w-[8%] bg-orange-200 rounded-sm" />
                    </div>
                    <Text
                      size="xl"
                      as="p"
                      className="mt-[18px] ml-px !font-rhodiumlibre !leading-10"
                    >
                      &#39;स्पेशलिटी स्टील&#39; के विनिर्माण को बढ़ावा देने के
                      लिए ₹6322 करोड़ के 5 साल के वित्तीय परिव्यय के साथ
                      प्रोडक्शन लिंक्ड इंसेंटिव (पीएलआई) योजना के तहत
                      &#39;स्पेशलिटी स्टील&#39; को शामिल करने की मंजूरी दे दी
                      है। 30,000 करोड़ रुपये की कुल निवेश प्रतिबद्धता, लगभग
                      18,000 की रोजगार सृजन क्षमता के साथ 24.78 मिलियन टन की कुल
                      प्रतिबद्ध क्षमता वृद्धि के साथ 27 कंपनियों द्वारा 57
                      समझौता ज्ञापनों पर हस्ताक्षर किए गए हैं। इससे 8 मीट्रिक टन
                      विशेष इस्पात का उत्पादन भी होगा। अब तक 10 हजार करोड़ का
                      निवेश हो चुका है।
                    </Text>
                  </div>
                  <div className="flex flex-col items-center justify-start sm:w-full w-[30%]">
                    <div className="h-[395px] w-full relative">
                      <Img
                        src="images/banner/item1.jpeg"
                        alt="image_one"
                        className="justify-center h-[395px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[96%] gap-[15px] bottom-[2%] right-0 left-0 m-auto bg-white-A700 absolute rounded-lg">
                        <Text
                          size="md"
                          as="p"
                          className="flex justify-center items-center h-[38px] w-[38px] ml-[7px] text-center bg-orange-300 rounded-[50%]"
                        >
                          04
                        </Text>
                        <Text size="md" as="p" className="w-[82%] mt-[3px]">
                          प्रोडक्शन लिंक्ड इंसेंटिव (पीएलआई) योजना:
                        </Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
