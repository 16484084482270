import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Heading } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";
import Gallery from "components/Slider/Gallery";
import image1 from "../../assets/jpg/349A7237 jpg.jpg";
import downloadImg from "../../assets/jpg/download.png";
import image2 from "../../assets/jpg/7204 jpg.jpg";
import image3 from "../../assets/jpg/7205 jpg.jpg";
import image4 from "../../assets/jpg/7217 jpg.jpg";
import image5 from "../../assets/jpg/7234 jpg.jpg";
import image6 from "../../assets/jpg/7247 jpg.jpg";
import image7 from "../../assets/jpg/7247 jpg.jpg";
import image8 from "../../assets/jpg/7274 jpg.jpg";
import image9 from "../../assets/jpg/7294 jpg.jpg";
import image10 from "../../assets/jpg/7307 jpg.jpg";
import image11 from "../../assets/jpg/7316 jpg.jpg";
import image12 from "../../assets/jpg/7322 jpg.jpg";
import image13 from "../../assets/jpg/7324 jpg.jpg";
import image14 from "../../assets/jpg/7326 jpg.jpg";
import image15 from "../../assets/jpg/7330 jpg.jpg";

export default function GalleryPage() {
  const handleDownload = (imgUrl) => {
    const link = document.createElement("a");
    link.href = imgUrl;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start pb-[100px]">
        <div className="flex flex-col items-center justify-start w-full overflow-auto">
          <Header />
          <div className="h-[538px] sm:h-[200px] w-full mt-[19px] relative">
            <Img
              src="images/img_rectangle_3_538x1440.png"
              alt="image"
              className="justify-center h-[538px] sm:h-[200px] w-full left-0 bottom-0 right-0 top-0 m-auto rounded-tr-[35px] rounded-tl-[35px] object-cover absolute"
            />
            <div className="w-full h-full pt-[100px] left-0 bottom-0 right-0 top-0 px-14 m-auto rounded-tl-[35px] rounded-tr-[35px] bg-gray-900_7c absolute">
              <div className="w-full mt-[22px] max-w-[1240px]">
                <Heading
                  size="2xl"
                  as="h1"
                  className="!text-white-A700 text-shadow-ts sm:text-[30px] sm:leading-[32px]"
                >
                  गैलरी
                </Heading>
              </div>
            </div>
          </div>
          <div className="w-full mt-[77px] px-0 sm:px-[16px] pb-[60px] sm:mt-[40px] max-w-[1240px]">
            <Heading size="lg" as="h2" className="ml-px !font-alegreya">
              फोटो
            </Heading>
            <div className="flex flex-row justify-start w-[15%] mt-[23px] gap-1">
              <div className="h-[4px] w-[89%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
            </div>
            <div className="grid mt-[40px] grid-cols-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image1} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image1)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image2} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image2)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image3} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image3)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image4} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image4)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image5} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image5)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image6} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image6)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image7} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image7)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image8} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image8)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image9} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image9)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image10} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image10)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image11} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image11)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image12} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image12)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image13} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image13)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image14} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image14)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
              <div className="shadow-lg rounded-[10px] relative">
                <img src={image15} className="rounded-[10px]" alt="" />
                <button
                  className="absolute top-[15px] right-[15px]"
                  onClick={() => handleDownload(image15)}
                >
                  <img
                    src={downloadImg}
                    className="h-[32px] w-[32px] "
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="w-full mt-[77px] px-0 sm:px-[16px] pb-[60px]  sm:mt-[40px] max-w-[1240px]">
            <Heading size="lg" as="h2" className="ml-px !font-alegreya">
              गैलरी
            </Heading>
            <div className="flex flex-row justify-start w-[15%] mt-[23px] gap-1">
              <div className="h-[4px] w-[89%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
            </div>
            <div className="gallery-slider mt-[30px]">
              <Gallery />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
