import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";

export default function DesktopEightPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  // const [list, setList] = useState("All");
  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start pb-[100px]">
        <div className="flex flex-col items-center justify-start w-full overflow-auto">
          <Header />
          <div className="h-[538px] sm:h-[200px] w-full mt-[19px] relative">
            <Img
              src="images/img_rectangle_3_538x1440.png"
              alt="image"
              className="justify-center h-[538px] sm:h-[200px] w-full left-0 bottom-0 right-0 top-0 m-auto rounded-tr-[35px] rounded-tl-[35px] object-cover absolute"
            />
            <div className="flex flex-row justify-center w-full h-full pt-[100px] left-0 bottom-0 right-0 top-0 px-14 m-auto rounded-tl-[35px] rounded-tr-[35px] bg-gray-900_7c absolute">
              <div className="flex flex-row justify-between items-center w-full mt-[22px] max-w-[1240px]">
                <Heading
                  size="2xl"
                  as="h1"
                  className="!text-white-A700 text-shadow-ts sm:text-[30px] sm:leading-[32px]"
                >
                  प्रेस एवं मीडिया
                </Heading>
                {/* <Img
                  src="images/img_image_removebg_preview_416x369.png"
                  alt="imageremovebg"
                  className="w-[30%] object-cover"
                /> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full mt-[77px] px-0 sm:px-[16px] pb-[60px]  sm:mt-[40px] max-w-[1240px]">
            <Heading size="lg" as="h2" className="ml-px !font-alegreya">
              List of articles
            </Heading>
            <div className="flex flex-row justify-start w-[15%] mt-[23px] gap-1">
              <div className="h-[4px] w-[89%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
            </div>
            {/* <div className="flex gap-[18px] flex-row sm:flex-col justify-start sm:justify-center items-center w-full mt-[30px]">
              <button
                onClick={() => setList("All")}
                className={
                  list === "All"
                    ? "bg-[#000000] text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#FFFFFF] leading-[40px]"
                    : "text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#000000] leading-[40px]"
                }
              >
                All
              </button>
              <button
                onClick={() => setList("Print article")}
                className={
                  list === "Print article"
                    ? "bg-[#000000] text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#FFFFFF] leading-[40px]"
                    : "text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#000000] leading-[40px]"
                }
              >
                Print article
              </button>
              <button
                onClick={() => setList("Electronic interviews")}
                className={
                  list === "Electronic interviews"
                    ? "bg-[#000000] text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#FFFFFF] leading-[40px]"
                    : "text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#000000] leading-[40px]"
                }
              >
                Electronic interviews
              </button>
              <button
                onClick={() => setList("Prodcasts")}
                className={
                  list === "Prodcasts"
                    ? "bg-[#000000] text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#FFFFFF] leading-[40px]"
                    : "text-[20px] font-[500] font-outfit px-[30px] rounded-[8px] text-[#000000] leading-[40px]"
                }
              >
                Prodcasts
              </button>
            </div> */}
            <div className="w-full mt-[49px] gap-[30px] grid-cols-2 sm:grid-cols-1 grid">
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://www.news18.com/opinion/opinion-scindias-and-indias-steel-industry-a-tale-of-tradition-innovation-and-leadership-8848143.html"
                >
                  <Img
                    src="images/union-ministe.webp"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      Opinion | Scindias and India’s Steel Industry: A Story of
                      Tradition, Innovation and Leadership
                    </Heading>
                    <a
                      href="https://www.news18.com/opinion/opinion-scindias-and-indias-steel-industry-a-tale-of-tradition-innovation-and-leadership-8848143.html"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://www.news18.com/opinion/opinion-scindias-and-indias-steel-industry-a-tale-of-tradition-innovation-and-leadership-8848143.html
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://thedailyguardian.com/guna-lok-sabha-constituency-and-scindias/"
                >
                  <Img
                    src="images/Jyotiraditya-M.jpg"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      Guna Lok Sabha constituency and Scindias
                    </Heading>
                    <a
                      href="https://thedailyguardian.com/guna-lok-sabha-constituency-and-scindias/"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://thedailyguardian.com/guna-lok-sabha-constituency-and-scindias/
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html"
                >
                  <Img
                    src="images/indiaimg.webp"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      A giant leap in India’s development journey
                    </Heading>
                    <a
                      href="https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html
                      </Text>
                    </a>
                  </div>
                </a>
              </div>

              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://organiser.org/2024/01/21/217178/opinion/ram-mandir-and-rajmata-scindia-the-inseparable-legacy/#google_vignette"
                >
                  <Img
                    src="images/Rectangle15.png"
                    alt="image"
                    className="w-full object-cover"
                  />
                  <div className="flex flex-col items-center justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      Ram Mandir and Rajmata Scindia: The Inseparable Legacy 
                    </Heading>
                    <a
                      href="https://organiser.org/2024/01/21/217178/opinion/ram-mandir-and-rajmata-scindia-the-inseparable-legacy/#google_vignette"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://organiser.org/2024/01/21/217178/opinion/ram-mandir-and-rajmata-scindia-the-inseparable-legacy/#google_vignette
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-3.5"
                  href="https://www.livehindustan.com/blog/story-hindustan-opinion-column-development-of-the-deprived-will-continuously-7-december-2023-9033993.html"
                >
                  <Img
                    src="images/union.jpg"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-full gap-1">
                    <Text size="3xl" as="p">
                      अनवरत जारी रहेगा वंचितों का विकास
                    </Text>
                    <a
                      href="https://www.livehindustan.com/blog/story-hindustan-opinion-column-development-of-the-deprived-will-continuously-7-december-2023-9033993.html"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://www.livehindustan.com/blog/story-hindustan-opinion-column-development-of-the-deprived-will-continuously-7-december-2023-9033993.html
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html"
                >
                  <Img
                    src="images/India.avif"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      India is making its mark in the world
                    </Heading>
                    <a
                      href="https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://www.youtube.com/watch?v=_Pv1-OifwdQ"
                >
                  <Img
                    src="images/img_rectangle_154.png"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      Interview with India Today on the future of flying cars,
                      civil aviation growth & more.
                    </Heading>
                    <a
                      href="https://www.youtube.com/watch?v=_Pv1-OifwdQ"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://www.youtube.com/watch?v=_Pv1-OifwdQ
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              {/* <div className="flex flex-col items-center justify-start w-full gap-px">
                <div className="flex flex-col items-start justify-start w-full gap-5">
                  <Img
                    src="images/India.avif"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <a
                    href="https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html"
                    className="ml-[3px]"
                  >
                    <Heading size="s" as="h4" className="!font-alegreya">
                       India is making its mark in the world
                    </Heading>
                  </a>
                </div>
                <a
                  href="https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.hindustantimes.com/opinion/indias-three-foundational-approaches-to-global-power-individual-competitiveness-competitive-federalism-and-human-centric-globalization-101685452010548.html
                  </Text>
                </a>
              </div> */}
              {/* <div className="flex flex-col items-start justify-start w-full">
                <Img
                  src="images/img_rectangle_154.png"
                  alt="image"
                  className="w-full object-cover"
                />
                <a
                  href="https://www.youtube.com/watch?v=_Pv1-OifwdQ"
                  className="w-[97%] mt-[15px] !leading-10"
                >
                  <Heading size="s" as="h5" className="!font-alegreya">
                    Interview with India Today on the future of flying cars,
                    civil aviation growth & more.
                  </Heading>
                </a>
                <a
                  href="https://www.youtube.com/watch?v=_Pv1-OifwdQ"
                  className="mt-[5px]"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.youtube.com/watch?v=_Pv1-OifwdQ
                  </Text>
                </a>
              </div> */}
              <div>
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://economictimes.indiatimes.com/opinion/et-commentary/budget-underpins-indias-strategy-from-amrit-kaal-to-shatabdi-kaal/articleshow/97627365.cms"
                >
                  <Img
                    src="images/img_rectangle_155.png"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      Budget underpins India&#39;s strategy from Amrit Kaal to
                      Shatabdi Kaal
                    </Heading>
                    <a
                      href="https://economictimes.indiatimes.com/opinion/et-commentary/budget-underpins-indias-strategy-from-amrit-kaal-to-shatabdi-kaal/articleshow/97627365.cms"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://economictimes.indiatimes.com/opinion/et-commentary/budget-underpins-indias-strategy-from-amrit-kaal-to-shatabdi-kaal/articleshow/97627365.cms
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              {/* <div className="flex flex-col items-center justify-start w-full">
                <Img
                  src="images/img_rectangle_155.png"
                  alt="image"
                  className="w-full object-cover"
                />
                <a
                  href=" https://economictimes.indiatimes.com/opinion/et-commentary/budget-underpins-indias-strategy-from-amrit-kaal-to-shatabdi-kaal/articleshow/97627365.cms"
                  className="w-[97%] mt-[15px] !leading-10"
                >
                  <Heading size="s" as="h6" className="!font-alegreya">
                    Budget underpins India&#39;s strategy from Amrit Kaal to
                    Shatabdi Kaal
                  </Heading>
                </a>
                <a
                  href=" https://economictimes.indiatimes.com/opinion/et-commentary/budget-underpins-indias-strategy-from-amrit-kaal-to-shatabdi-kaal/articleshow/97627365.cms"
                  className="!leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://economictimes.indiatimes.com/opinion/et-commentary/budget-underpins-indias-strategy-from-amrit-kaal-to-shatabdi-kaal/articleshow/97627365.cms
                  </Text>
                </a>
              </div> */}
              <div className=" overflow-hidden ">
                <a
                  className="flex flex-col items-center justify-start w-full gap-5"
                  href="https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/govt-wants-to-make-civil-aviation-accessible-and-affordable-for-indians/articleshow/96648950.cmsutm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&s=08&from=mdr"
                >
                  <Img
                    src="images/img_rectangle_156.png"
                    alt="image"
                    className="w-full h-[262px] object-cover"
                  />
                  <div className="flex flex-col items-start justify-start w-[97%] gap-px">
                    <Heading size="s" as="h3" className="!font-alegreya">
                      Govt wants to make civil aviation accessible and
                      affordable for Indians
                    </Heading>
                    <a
                      href="https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/govt-wants-to-make-civil-aviation-accessible-and-affordable-for-indians/articleshow/96648950.cmsutm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&s=08&from=mdr"
                      className="!leading-7"
                    >
                      <Text
                        size="md"
                        as="p"
                        className="!text-black-900_cc !font-outfit !font-normal underline"
                      >
                        https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/govt-wants-to-make-civil-aviation-accessible-and-affordable-for-indians/articleshow/96648950.cmsutm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&s=08&from=mdr
                      </Text>
                    </a>
                  </div>
                </a>
              </div>
              {/* <div className="flex flex-col items-center justify-start w-full">
                <Img
                  src="images/img_rectangle_156.png"
                  alt="image"
                  className="w-full object-cover"
                />
                <a
                  href=" https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/govt-wants-to-make-civil-aviation-accessible-and-affordable-for-indians/articleshow/96648950.cmsutm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&s=08&from=mdr"
                  className="w-[97%] mt-[15px] !leading-10"
                >
                  <Heading size="s" as="h4" className="!font-alegreya">
                    Govt wants to make civil aviation accessible and affordable
                    for Indians
                  </Heading>
                </a>
                <a
                  href=" https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/govt-wants-to-make-civil-aviation-accessible-and-affordable-for-indians/articleshow/96648950.cmsutm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&s=08&from=mdr"
                  className="w-full mt-px ml-px !leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit overflow-hidden !font-normal underline"
                  >
                    https://economictimes.indiatimes.com/industry/transportation/airlines-/-aviation/govt-wants-to-make-civil-aviation-accessible-and-affordable-for-indians/articleshow/96648950.cmsutm_source=contentofinterest&utm_medium=text&utm_campaign=cppst&s=08&from=mdr
                  </Text>
                </a>
              </div> */}

              {/* <div className="flex flex-col items-start justify-start w-full">
                <Img
                  src="images/indiaimg.webp"
                  alt="image_one"
                  className="w-[100%] h-[262px] ml-px object-cover"
                />
                <a
                  href="https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html"
                  className="mt-3"
                >
                  <Heading size="s" as="h4" className="!font-alegreya">
                    A giant leap in India’s development journey
                  </Heading>
                </a>
                <a
                  href="https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html"
                  className="w-full mt-px ml-px !leading-7"
                >
                  <Text
                    size="md"
                    as="p"
                    className="!text-black-900_cc !font-outfit !font-normal underline"
                  >
                    https://www.hindustantimes.com/opinion/taking-a-giant-leap-in-the-defence-sector-101668431827165.html
                  </Text>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
