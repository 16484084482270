import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading } from "../../components";
import Header from "../../components/Header";
import Footer from "components/footer/Footer";

export default function DesktopSevenPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start">
        <div className="flex flex-col items-center justify-start w-full overflow-auto">
          <Header />
          <div className="h-[538px] sm:h-[200px] w-full mt-[19px] relative">
            <Img
              src="images/img_rectangle_3.png"
              alt="image"
              className="justify-center h-[538px] sm:h-[200px] w-full left-0 bottom-0 right-0 top-0 m-auto rounded-tr-[35px] rounded-tl-[35px] object-cover absolute"
            />
            <div className="flex flex-row justify-center w-full h-full pt-11 left-0 bottom-0 right-0 top-0 px-11 m-auto rounded-tl-[35px] rounded-tr-[35px] bg-gray-900_7c absolute">
              <div className="flex flex-row justify-between items-center w-full mt-[158px] sm:mt-[80px] max-w-[1296px]">
                <Heading
                  size="2xl"
                  as="h1"
                  className="!text-white-A700 sm:text-[40px] text-shadow-ts"
                >
                  मेरा संकल्प
                </Heading>
                {/* <Img
                  src="images/img_img_20240216_wa.png"
                  alt="img20240216wa"
                  className="w-[43%] object-cover"
                /> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start w-full mt-[72px] pb-[150px] px-0 sm:px-[16px] max-w-[1191px]">
            <div className="flex flex-row justify-start w-full items-center gap-[7px]">
              <div className="h-px w-[1%] bg-yellow-900" />
              <Text
                size="xl"
                as="p"
                className="!text-yellow-900 w-full text-start"
              >
                वादे और इरादे
              </Text>
            </div>
            <Text
              size="5xl"
              as="p"
              className="mt-[7px] !font-outfit text-center"
            >
              <span className="text-black-900_01 font-poppins">
                विकास कार्य
              </span>
              <span className="text-black-900_01"></span>
              <span className="text-black-900_01"></span>
              {/* <span className="text-black-900_01">(manifesto):</span> */}
            </Text>
            <div className="flex flex-row justify-start w-[15%] mt-[18px] gap-1">
              <div className="h-[4px] w-[89%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
              <div className="h-[4px] w-[4%] bg-green-A700 rounded-sm" />
            </div>
            <div className="flex flex-row justify-start items-start w-full mt-[66px] sm:mt-[30px] gap-5">
              <div className="flex flex-col items-center sm:hidden justify-start w-[3%]">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="h-[2798px] w-full relative">
                    <div className="flex flex-row justify-center w-max h-full left-0 bottom-0 right-0 top-0 m-auto absolute">
                      <Img
                        src="images/img_vector_yellow_900.svg"
                        alt="vector_one"
                        className="h-[2798px] z-[1]"
                      />
                      <Img
                        src="images/img_vector_black_900_01_2265x9.svg"
                        alt="vector_three"
                        className="h-[2798px] ml-[-7px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full top-[12%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector.svg"
                          alt="vector_five"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector_seven"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_amber_400.svg"
                          alt="vector_nine"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector_eleven"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[1.4%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_deep_orange_200.svg"
                          alt="vector_thirteen"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector_fifteen"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector_nineteen"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[6.6%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_gray_600.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_gray_600_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[17%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[22%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector_fortyone"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[27%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector_fiftyone"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[32%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full top-[37%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector_sixtyone"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full h-max left-0 bottom-0 right-0 top-[-15%] m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[52%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[47%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[42.2%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[37.2%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <div className="h-[28px] w-[28px] ml-[-25px] relative">
                        <Img
                          src="images/img_vector_cyan_200_28x28.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_black_900_01.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[32%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[26.8%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[21.7%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[16.6%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[11.6%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[6.2%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                    <div className="flex flex-row justify-center w-full bottom-[1%] right-0 left-0 m-auto absolute">
                      <div className="h-[28px] w-[28px] z-[1] relative">
                        <Img
                          src="images/img_vector_cyan_200.svg"
                          alt="vector"
                          className="justify-center h-[28px] w-[28px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                        <Img
                          src="images/img_vector_white_a700.svg"
                          alt="vector"
                          className="justify-center h-[16px] w-[16px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                        />
                      </div>
                      <Img
                        src="images/img_vector_cyan_200_28x28.svg"
                        alt="vector"
                        className="h-[28px] w-[28px] ml-[-25px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start w-[96%] mt-[20px] sm:w-[100%] gap-[72px] sm:gap-5">
                <div className="flex flex-col w-[83%] sm:w-[100%] gap-16 sm:gap-5">
                  <div className="flex flex-row justify-start w-[64%] sm:w-[100%] mr-[339px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="image"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[98%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            01
                          </Text>
                        </div>
                        <Text as="p" className="sm:mt-0 sm:text-[16px]">
                          गुना में चिकित्सा क्षेत्र के विद्यार्थियों और
                          स्वास्थ्य सुविधा को बेहतर करने हेतु मेडिकल कॉलेज की
                          स्थापना की जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-[100%] sm:w-[100%] mr-[624px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorsix_one"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[95%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            02
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          क्षेत्र में घर-घर तक सड़क संपर्क बनाने हेतु वर्तमान
                          सड़कों की मरम्मत और नवीन सड़कों के निर्माण का कार्य किया
                          जायेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-[100%] sm:w-[100%] mr-[632px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorseven_one"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[95%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            03
                          </Text>
                        </div>
                        <Text
                          as="p"
                          className="mt-[13px] sm:mt-0 sm:text-[16px]"
                        >
                          पेयजल की निर्बाध आपूर्ति की दिशा में घर-घर तक नल के
                          माध्यम से जल की उपलब्धता सुनिश्चित की जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-[100%] sm:w-[100%] mr-[395px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectoreight_one"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            04
                          </Text>
                        </div>
                        <Text as="p" className="sm:mt-0 sm:text-[16px]">
                          गुना सर्किट रोड का काम शीघ्रातिशीघ्र पूर्ण किया
                          जायेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center w-full">
                    <div className="flex flex-row justify-center items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectornine_one"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[99%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            05
                          </Text>
                        </div>
                        <Text as="p" className="sm:mt-0 sm:text-[16px]">
                          अन्य शहरों का गुना से हवाई संपर्क बने इस हेतु गुना
                          हवाईपट्टी का विकास किया जाएगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[437px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorten_one"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            06
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          ट्रैफिक समस्या के पूर्ण समाधान हेतु युद्ध स्तर पर
                          कार्य किया जाएगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[280px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectoreleven"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[98%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            07
                          </Text>
                        </div>
                        <Text as="p" className="sm:mt-0 sm:text-[16px]">
                          आदिवासी समाज के छात्र-छात्राओं के लिए क्षेत्र में
                          प्रमुख स्थानों पर सुव्यवस्थित एवं सर्व सुविधा युक्त
                          छात्रावासों की स्थापना की जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[412px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectortwelve"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            08
                          </Text>
                        </div>
                        <Text as="p" className="sm:mt-0 sm:text-[16px]">
                          खेतों में पम्प फीडर से बिजली आपूर्ति सुनिश्चित की
                          जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[412px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorthirteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[97%] ml-[-21px] gap-5 p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            09
                          </Text>
                        </div>
                        <Text as="p" className="sm:mt-0 sm:text-[16px]">
                          सर्वसुविधायुक्त और उच्च स्तरीय रेलवे स्टेशन के रूप में
                          गुना रेलवे स्टेशन का विकास किया जायेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            10
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          क्षेत्र के युवाओं के लिए रोजगार के नये अवसर उपलब्ध
                          कराए जाएंगे।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row sm:justify-center justify-start sm:items-center items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            11
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          किसान बन्धुओं की सुविधा के लिए मंडियों का जीर्णोद्धार
                          किया जायेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start sm:items-center items-start w-[98%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            12
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          सभी मंडियों को ई-मंडी से जोड़ने हेतु विशेष
                          कम्प्यूटराइज्ड केंद्रों की स्थापना होगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-center items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            13
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          भ्रष्टाचार के प्रति जीरो टॉलरेंस की नीति को अपनाकर नगर
                          पालिकाओं में भ्रष्टाचार को शून्य किया जायेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-center items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            14
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          विकसित भारत योजना के तहत क्षेत्र में केंद्र व राज्य
                          सरकार की सभी जनकल्याणकारी योजनाओं का उचित क्रियान्वयन
                          सुनिश्चित किया जायेगा जिससे हर वर्ग - हर व्यक्ति को इन
                          योजनाओं का लाभ मिलेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-center items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            15
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          क्षेत्र के धार्मिक स्थलों, मठ-मन्दिरों के साथ पर्यटन
                          स्थलों का भी जीर्णोद्धार कराया जायेगा एवं सुरक्षा व
                          संरक्षण की दृष्टि से बाउंड्रीवॉल निर्माण इत्यादि कार्य
                          किए जाएंगे।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            16
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          क्षेत्र में प्रमुख स्थानों पर गौशालाओं का सुव्यवस्थित
                          संचालन सुनिश्चित होगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-center items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            17
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          गुना-अशोकनगर-शिवपुरी क्षेत्र के अंतर्गत आने वाले सभी
                          हाईवे एवं नगरीय सड़कों पर एलईडी लाइट की व्यवस्था की
                          जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            18
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          क्षेत्र में जहां भी अतिक्रमण हैं उस स्थान को अतिक्रमण
                          मुक्त कराया जायेगा।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-center items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            19
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          बाजार व सड़क इत्यादि सार्वजनिक स्थानों पर महिला सुरक्षा
                          की दृष्टि से प्राथमिकता के साथ महिला पुलिस बूथ की
                          स्थापना की जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-start w-full sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-center items-center w-[100%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <div>
                          <Text
                            size="5xl"
                            as="p"
                            className="flex justify-center items-center h-[58px] sm:h-[30px] sm:w-[30px] w-[58px] sm:text-[12px] mt-px text-center bg-white-A700 rounded-[50%]"
                          >
                            20
                          </Text>
                        </div>
                        <Text as="p" className="mt-3.5 sm:mt-0 sm:text-[16px]">
                          'विकसित गुना - शिक्षित गुना' के संकल्प को साकार करने
                          के लिए क्षेत्र में वृहद स्तर के शासकीय व अशासकीय
                          शिक्षण संस्थानों की स्थापना की जायेगी।
                        </Text>
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex flex-row justify-start w-[52%] sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <Text
                          size="5xl"
                          as="p"
                          className="flex justify-center items-center h-[58px] w-[58px] mt-px text-center bg-white-A700 rounded-[50%]"
                        >
                          14
                        </Text>
                        <Text as="p" className="mt-3.5">
                          धार्मिक स्थलों, मठ - मंदिरो का जीर्णोद्धार व सभी की
                          बाउंड्री
                        </Text>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="flex flex-row justify-start w-[52%] sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <Text
                          size="5xl"
                          as="p"
                          className="flex justify-center items-center h-[58px] w-[58px] mt-px text-center bg-white-A700 rounded-[50%]"
                        >
                          15
                        </Text>
                        <Text as="p" className="mt-3.5">
                          गौशालाओं का पूर्ण संचालन
                        </Text>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="flex flex-row justify-start w-[52%] sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <Text
                          size="5xl"
                          as="p"
                          className="flex justify-center items-center h-[58px] w-[58px] mt-px text-center bg-white-A700 rounded-[50%]"
                        >
                          16
                        </Text>
                        <Text as="p" className="mt-3.5">
                          हाई वे व नगरीय सड़क पर एलईडी लाइट की व्यवस्था
                        </Text>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="flex flex-row justify-start w-[52%] sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <Text
                          size="5xl"
                          as="p"
                          className="flex justify-center items-center h-[58px] w-[58px] mt-px text-center bg-white-A700 rounded-[50%]"
                        >
                          17
                        </Text>
                        <Text as="p" className="mt-3.5">
                          अतिक्रमण से मुक्ति
                        </Text>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="flex flex-row justify-start w-[52%] sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <Text
                          size="5xl"
                          as="p"
                          className="flex justify-center items-center h-[58px] w-[58px] mt-px text-center bg-white-A700 rounded-[50%]"
                        >
                          18
                        </Text>
                        <Text as="p" className="mt-3.5">
                          बाज़ार व सड़कों पर महिला सुरक्षा सुनिश्चित करने के लिए
                          महिला पोलिस बूथ की स्थापना
                        </Text>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="flex flex-row justify-start w-[52%] sm:w-[100%] mr-[454px]">
                    <div className="flex flex-row justify-start items-center w-full">
                      <Img
                        src="images/img_vector_5.svg"
                        alt="vectorfourteen"
                        className="h-[18px]"
                      />
                      <div className="flex flex-row justify-start items-start w-[97%] ml-[-21px] gap-[19px] p-2 bg-yellow-900_7f rounded-[37px]">
                        <Text
                          size="5xl"
                          as="p"
                          className="flex justify-center items-center h-[58px] w-[58px] mt-px text-center bg-white-A700 rounded-[50%]"
                        >
                          19
                        </Text>
                        <Text as="p" className="mt-3.5">
                          बड़े सरकारी - ग़ैर सरकारी शिक्षण संस्थान की स्थापना 
                        </Text>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
