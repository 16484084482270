import React from "react";

const sizes = {
  "2xl": "text-[76px] font-semibold leading-[148px]",
  xl: "text-5xl font-semibold leading-[72px]",
  s: "text-2xl font-bold leading-[33px]",
  md: "text-[32px] font-semibold leading-[48px]",
  xs: "text-xl font-semibold leading-7",
  lg: "text-[40px] font-extrabold leading-[55px]",
};

const Heading = ({ children, className = "", size = "xs", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-black-900_01 font-poppins ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
